import { fetchSpecialHours } from './fetchSpecialHours';

// Get month from UNIX timestamp
export function getMonth(timestamp) {
	// Create a new Date object using the UNIX timestamp
	const date = new Date(timestamp);

	// Define months array
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	// Get individual date components
	return months[date.getMonth()];
}

export function getMonthDay(timestamp) {
	const test = typeof timestamp;
	// Create a new Date object using the UNIX timestamp
	const date = new Date(timestamp);
	const day = date.getDate();
	const month = getMonth(timestamp);

	// Create the formatted date string
	return `${month} ${day}`;
}

// Get year from UNIX timestamp
export function getYear(timestamp) {
	const date = new Date(timestamp);
	return date.getFullYear();
}

export function isDiffYear(date1, date2) {
	const year1 = new Date(date1).getFullYear();
	const year2 = new Date(date2).getFullYear();
	const yearDiff = year2 - year1;
	return Math.abs(yearDiff) > 0 ? true : false;
}

// Get time from UNIX timestamp
export function getTime(timestamp) {
	const date = new Date(timestamp);

	let hours = date.getHours();
	const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	if (hours > 12) {
		hours -= 12;
	} else if (hours == 0) {
		hours = 12;
	}
	return `${hours}:${minutes} ${ampm}`;
}

// Check if two timestamps are the same day
export function isLongerThanDay(timestamp1, timestamp2) {
	// Calculate the difference in milliseconds
	const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);

	// Number of milliseconds in a day
	const dayInMilliseconds = 24 * 60 * 60 * 1000;

	// Check if the difference is longer than a day
	return differenceInMilliseconds > dayInMilliseconds;
}

// Check if two dates are the same day
export function isSameDate(timestamp1, timestamp2) {
	return new Date(timestamp1).getDate() == new Date(timestamp2).getDate();
}

// Check if two date are the same month
export function isSameMonth(timestamp1, timestamp2) {
	return new Date(timestamp1).getMonth() == new Date(timestamp2).getMonth();
}

// Check if two date are the same year
export function isSameYear(timestamp1, timestamp2) {
	return new Date(timestamp1).getFullYear() == new Date(timestamp2).getFullYear();
}

export function stringToDate(string) {
	if (!string) return '';
	return new Date(string);
}

export function changeTimezone(date, timeZone = 'America/Chicago') {
	const realDate = new Date(typeof date === 'string' ? new Date(date) : date);
	return realDate.toLocaleString('en-US', { timeZone: timeZone });
}

export function getDayOfWeek(
	date,
	days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
) {
	return days[date.getDay()];
}

// Convert date to a different format
// Let's add to this overtime when we need new formats
export function convertFormat(date, format = 'yyyy-mm-dd', timeZone = null) {
	const dateObj = new Date(date);

	let year = dateObj.getFullYear();
	let month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
	let day = dateObj.getDate().toString().padStart(2, '0');

	if (timeZone) {
		const localString = dateObj.toLocaleString('en-US', { timeZone });
		if (localString) {
			const date = localString.split(',')[0];
			const time = localString.split(', ')[1];

			year = date.split('/')[2];
			month = date.split('/')[0].padStart(2, '0');
			day = date.split('/')[1].padStart(2, '0');
		}
	}

	format = format.toLowerCase();

	switch (format) {
		case 'yyyy-mm-dd':
			return `${year}-${month}-${day}`;
		case 'mm-dd-yyyy':
			return `${month}-${day}-${year}`;
		default:
			return `${month}-${day}-${year}`;
	}
}

export const getNowSafely = async () => {
	const now = useState('now');
	await callOnce(async () => {
		now.value = new Date();
	});
	return now;
};

export const convertTimeTo24 = (time = '9:00 AM') => {
	const hours = parseInt(time.split(':')[0]);
	const minutes = time.split(':')[1].split(' ')[0];
	const am = time.split(' ')[1].toLowerCase() === 'am';

	if (am && hours === 12) return `00:${minutes}`;
	if (!am && hours < 12) return `${hours + 12}:${minutes}`;
	return `${hours.toString().padStart(2, '0')}:${minutes}`;
};

export const isDayLightSavingTime = (date) => {
	// As of 2007, Daylight Saving Time begins in the United States on the second Sunday in March
	// and ends on the first Sunday in November. On the second Sunday in March, clocks are set
	// ahead one hour at 2:00 a.m. local standard time (which becomes 3:00 a.m.
	// local Daylight Saving Time). On the first Sunday in November, clocks are set back one hour
	// at 2:00 a.m. local Daylight Saving Time (which becomes 1:00 a.m. local standard time).
	// https://greenwichmeantime.com/articles/clocks/iso/

	if (typeof date === 'string') date = new Date(date);
	const year = date.getFullYear();

	const dstStart = findDSTStart(year);
	const dstEnd = findDSTEnd(year);
	return date >= dstStart && date < dstEnd;
};

const findDSTStart = (year) => {
	const firstDayOfMonth = new Date(year, 2, 1);
	const firstSunday = 7 - firstDayOfMonth.getDay() + 1; // Day of the first Sunday
	const secondSunday = firstSunday + 7; // Day of the second Sunday

	const date = new Date(year, 2, secondSunday, 8 - getServerOffset() / 60);
	return date;
};

const findDSTEnd = (year) => {
	const firstDayOfMonth = new Date(year, 10, 1);
	const firstSunday = 7 - firstDayOfMonth.getDay() + 1; // Day of the first Sunday
	return new Date(year, 10, firstSunday, 6 - getServerOffset() / 60);
};

const getServerOffset = () => {
	// The server pulls in the machines local time zone, which should be UTC in Production
	const date = new Date();
	const offset = date.getTimezoneOffset();
	return offset;
};

export const calculateDecadeByYear = (year) => {
	if (typeof year == 'number') return Math.floor(year / 10) * 10;
	return year;
};

export const calculateDuration = (startTimestamp, endTimestamp) => {
	// Calculate the duration in milliseconds
	const durationMs = endTimestamp - startTimestamp;

	// Convert duration from milliseconds to minutes
	const durationMinutes = durationMs / (1000 * 60);

	// Determine the duration category
	return durationMinutes;
};

export const isDateInRange = (dateToCheck, startDate, endDate) => {
	// Convert all dates to Date objects if they aren't already
	const date = new Date(dateToCheck);
	const start = new Date(startDate);
	const end = new Date(endDate);

	// Check if the date is within the range (inclusive)
	return date >= start && date <= end;
};

export const formatProgramDate = (performances) => {
	const rootPostDates = [];
	performances.forEach((date) => {
		let rawStart = date?.performanceDateTime ? new Date(date?.performanceDateTime) : null;
		let rawEnd = date?.performanceDateTimeEnd ? new Date(date?.performanceDateTimeEnd) : null;
		let startMonth = '';
		let endMonth = '';
		const dateObj = {};
		let datesSameDay = false;

		if (rawStart) {
			startMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(rawStart);
			if (rawEnd) {
				dateObj.startDate = new Intl.DateTimeFormat('en-US', {
					month: 'long',
					day: 'numeric',
					timeZone: 'America/Chicago',
				}).format(rawStart);
			} else {
				dateObj.startDate = new Intl.DateTimeFormat('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric',
					timeZone: 'America/Chicago',
				}).format(rawStart);
			}

			dateObj.startTime = new Intl.DateTimeFormat('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				timeZone: 'America/Chicago',
			}).format(rawStart);
		}
		if (rawEnd) {
			endMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(rawEnd);

			if (startMonth === endMonth) {
				dateObj.endDate = new Intl.DateTimeFormat('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric',
				}).format(rawEnd);
			} else {
				dateObj.endDate = new Intl.DateTimeFormat('en-US', {
					month: 'long',
					day: 'numeric',
					year: 'numeric',
				}).format(rawEnd);
			}

			dateObj.endTime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' }).format(rawEnd);
		}
		if (rawStart && rawEnd) {
			if (isSameDate(rawStart, rawEnd)) datesSameDay = true;
			else datesSameDay = false;
			dateObj.sameDay = datesSameDay;
		}
		rootPostDates.push(dateObj);
	});
	return rootPostDates;
};
